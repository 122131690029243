<template>
  <div class="min-h-screen">
    <div class="p-4 mt-24 bg-white max-w-screen-lg mx-auto container">
      <p class="text-xs uppercase tracking-widest">
        Pembaruan terakhir: {{ lastUpdated | indonesian_datetime_format }}
      </p>
      <toast-editor
        class="mt-2 mb-4"
        ref="toastuiEditor"
        initialEditType="markdown"
        :initial-value="form.content"
        :options="editor.options"
        :height="editor.height"
        preview-style="tab"
        @change="onEditorChange"
      ></toast-editor>
      <button class="button --primary" @click="triggerStoreContent()">
        <fa-icon icon="save"></fa-icon>
        <span class="ml-2">{{
          isProcessing ? "dalam proses..." : "Simpan"
        }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";

import { Editor } from "@toast-ui/vue-editor";
import _debounce from "lodash/debounce";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lastUpdated: null,
      isProcessing: false,
      form: {
        content: "",
      },
      editor: {
        options: {
          usageStatistics: false,
          hideModeSwitch: true,
        },
        height: "500px",
      },
    };
  },
  methods: {
    getContent() {
      return this.$refs.toastuiEditor.invoke("getMarkdown");
    },
    setContent(content) {
      return this.$refs.toastuiEditor.invoke("setMarkdown", content);
    },
    onEditorChange: _debounce(
      function () {
        this.form.content = this.getContent();
      },
      700,
      { maxWait: 1000 }
    ),
    async triggerStoreContent() {
      if (this.isProcessing) {
        return false;
      }
      this.isProcessing = true;
      await this.storeContent(this.form);
      this.isProcessing = false;
      this.lastUpdated = this.education_principle.updated_at;
    },
    /** Actions fromo information */
    ...mapActions("registration_information", ["storeContent", "fetchContent"]),
  },
  computed: {
    ...mapGetters("registration_information", ["information"]),
  },
  async created() {
    await this.fetchContent();
    this.setContent(this.information.content);
    this.lastUpdated = this.information.updated_at;
  },
  beforeDestroy() {
    this.$refs.toastuiEditor.invoke("destroy");
  },
  name: "RegistrationInformation",
  components: {
    "toast-editor": Editor,
  },
};
</script>